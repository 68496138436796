import {PRACTITIONERS} from "../Constants";

// If URL indicates a different practitioner, use that
const foundPractitioner = PRACTITIONERS.find((practitioner) => {
    const matcher = practitioner["location_matcher"];
    return matcher && matcher(window.location);
})

const ACTIVE_PRACTITIONER_DB = (foundPractitioner || PRACTITIONERS[0])["db"] // DR_RITAMARIE_LOSCALZO_PRACTITIONER_JSON //STEPH_JACKSON_FFC_PRACTITIONER_JSON //

export let practitionerDB = Object.assign({}, {status: "loaded"}, ACTIVE_PRACTITIONER_DB)

let event = practitionerDB.events[0] // TODO DPB DRIVE THIS OFF URL?PASSPHRASE or entered passphrase
let snpGroupsForEvent = event.use_snp_groups.map((groupName) => practitionerDB.snp_groups[groupName]);

export let eventInfo = Object.assign({}, event, {"snp_groups": snpGroupsForEvent},);





function annotationsForSNP(snpInterpretation, myGenome, extraInfo) {
    let snpListWithAnnotations = []
    let counts = [0, 0, 0, 0, 0]
    // TODO DPB update local storage and when running next time, initialize from local storage
    //
    let snpName = snpInterpretation["snp_name"]
    let snpAnnotated;
    if (snpInterpretation) {
        let rsIds = snpInterpretation["rsIds"]
        if (rsIds) {
            rsIds.forEach((rsIdInterpretation) => {
                let myRsidInfo = {}
                myRsidInfo["variant_name"] = rsIdInterpretation["variant_name"]
                let rsId = rsIdInterpretation["rsId"]
                rsId = rsId ? rsId.toLowerCase() : null
                myRsidInfo["rsId"] = rsId
                let myGenotype = myGenome && rsId ? myGenome[rsId] : null
                myRsidInfo["my_genotype"] = myGenotype
                let risk_alleles = rsIdInterpretation["risk_alleles"]
                /*if (DEBUG >= DEBUG_DETAIL) {
                    console.log({"snp": snpName, "rsId": rsId, "my_genotype": myGenotype, "risk_alleles": risk_alleles})
                }*/
                if (risk_alleles) {
                    // TODO DPB if I have one each of risk alleles, should not be yellow & yellow ?
                    risk_alleles.forEach((riskAlleleInterpretation) => {
                        let myRiskAlleleSatus = {}
                        let riskAllele = riskAlleleInterpretation["allele"]
                        if (riskAllele && riskAllele !== "--") {
                            riskAllele = riskAllele.toUpperCase()
                            let riskAlleleMatchCount = myGenome ? 3 : 4 // NA Icon and Hourglass Icon
                            if (myGenotype && myGenotype !== "--") {
                                // TBD --combining personal data into Interpretation data
                                riskAlleleMatchCount = 0 // No matches to this risk allele [Note: this is not comparing to typical allele]
                                if (myGenotype) {
                                    if (myGenotype.startsWith(riskAllele)) {
                                        riskAlleleMatchCount++    // heterozygous or homozygous (if other allele matches risk allele)
                                    }
                                    if (myGenotype[1] === riskAllele) {
                                        riskAlleleMatchCount++    // homozygous
                                    }
                                }
                            }
                            myRiskAlleleSatus = {"risk_allele": riskAllele, "my_status": riskAlleleMatchCount}
                            counts[riskAlleleMatchCount] += 1
                        }
                        snpAnnotated = Object.assign({}, snpInterpretation, myRsidInfo, riskAlleleInterpretation, myRiskAlleleSatus, extraInfo)
                        snpListWithAnnotations.push(snpAnnotated)
                    })
                }
            })
        } else {
            /*if (DEBUG >= DEBUG_DETAIL) {
                console.log("No RsIDs for " + snpName)
            }*/
            snpAnnotated = Object.assign({}, snpInterpretation, extraInfo)
            snpListWithAnnotations.push(snpAnnotated)
        }
    } else {
        /*if (DEBUG >= DEBUG_DETAIL) {
            console.log("No interpretation for " + snpName)
        }*/
        snpAnnotated = Object.assign({}, {"snp_name": snpName}, extraInfo)
        snpListWithAnnotations.push(snpAnnotated)
    }
    return [snpListWithAnnotations, counts]
}

const annotationsForSnpList = (snpsByGroup, snpInterpretationsDict, myGenomeDict) => {
    let snpInterpretations = []
    snpsByGroup.forEach((group) =>
        group["SNPs"].forEach((snpName) => {
                let snpInterpretation = snpInterpretationsDict[snpName]
                if (snpInterpretation) {
                    snpInterpretations.push({snpInterpretation, group})
                } else {
                    console.log("Expected to find snp interpretation for '" + snpName + "' in event's SNP group named " + group["group"])
                    //alert("Expected to find snp interpretation for '" + snpName + "'")
                }
            }
        ))
    let snpListAnnotations = []
    snpInterpretations.forEach(({snpInterpretation, group}) => {
        let extraInfo = {"group": group["group"]}
        let [annotatedSnps] = annotationsForSNP(snpInterpretation, myGenomeDict, extraInfo)
        annotatedSnps.forEach((anno) => snpListAnnotations.push(anno))
    })
    return snpListAnnotations
}

export default annotationsForSnpList
import './App.css';
import React, {useState} from "react";
import annotationsForSnpList from "./services/AnnotateGenome";
import PassphraseForm from "./components/passphrasePage/PassphraseForm";
import SnpsSearchForm from "./components/SnpsSearchForm";
import RsIdsTable from "./components/RsIdsTable";
import AnnotatedSNPsTable from "./components/AnnotatedSNPsTable";
import selectFilterAndSortSnps from "./services/SelectFilterAndSortSnps";
import {eventInfo, practitionerDB} from "./services/PractitionerDB";
import SelectAndLoadGenomeDataFile from "./components/SelectAndLoadGenome";
import CopyrightAndDiscliamer from "./components/CopyrightAndDisclaimer";
import RsIdsSearchForm from "./components/RsIdsSearchForm";
import {INTRODUCTION, SNPS_GROUP_FROM_URL_QUERY_PARAMETERS} from "./Constants";
import {SnpGroupSelector} from "./services/Selector";

const composeStats = (annotatedSnpList) => {
  let stats = {}
  stats["Interpretations"] = annotatedSnpList.length
  const genomeCount = annotatedSnpList?.reduce((total,snpInfo) => (snpInfo["my_genotype"] ? total+1 : total), 0)
  stats["Interpretations with your status"] = genomeCount || "No genome.  Have you located your data file?"
  return stats;
}

function App() {
  let INITIAL_SELECTORS = []
  if (SNPS_GROUP_FROM_URL_QUERY_PARAMETERS && SNPS_GROUP_FROM_URL_QUERY_PARAMETERS != "") {
      // TODO DPB move this to run when passphrase has just processed and verify the group exists
      INITIAL_SELECTORS.push(new SnpGroupSelector(decodeURIComponent(SNPS_GROUP_FROM_URL_QUERY_PARAMETERS)))
  }
  const INITIAL_SELECTORS_FILTER_AND_SORT = {selectors: INITIAL_SELECTORS, filters: {justMyGenomeViewFilter: false}, sort: {alphaBySnpNameSort: false}}
  const [snpInterpretationsDict, setSnpInterpretationsDict] = useState({});
  const [myGenome, setMyGenome] = useState({isLoaded: false, dict: undefined});  // TODO DPB  do away with this -- just use it when read in
  const [rsIdSelectors, setRsIdSelectors] = useState([])
  const [selectorsFiltersAndSort, setSelectorsFiltersAndSort] = useState(INITIAL_SELECTORS_FILTER_AND_SORT)

  const toggleMainSort = () => {
      const newSort = {sort: {alphaBySnpNameSort: !selectorsFiltersAndSort.sort["alphaBySnpNameSort"]}}; // TODO DPB if sort is singular, remove nesting to prevent ambiguity
      setSelectorsFiltersAndSort(Object.assign({}, selectorsFiltersAndSort, newSort));
  }
  const toggleJustMyGenomeFilter = () => {
      const newSetting = myGenome.isLoaded && !selectorsFiltersAndSort.filters["justMyGenomeViewFilter"]
      setSelectorsFiltersAndSort((existing) => { return {...existing, filters: {...existing.filters, justMyGenomeViewFilter: newSetting}}});   // , filters: }});
  }

  const setSelectors = (selectorsSetter) => {
      setSelectorsFiltersAndSort((existing) => { return {...selectorsFiltersAndSort, selectors: selectorsSetter(existing.selectors)}});
  }

  const clearSelector = (selector) => {
      setSelectors((existing) => existing.filter(exists => exists !== selector));
  }

  const rsIdSelectionsList = rsIdSelectors?.map((selector) => { return {selector, selections: selector.select(myGenome.dict, {} /*snpListAnnotated*/)}});

  const snpListAnnotated = /*myGenome.isLoaded && */ annotationsForSnpList(eventInfo["snp_groups"], snpInterpretationsDict, myGenome.dict);
  const stats = composeStats(snpListAnnotated);
  let {selectedFilteredAndSortedSNPs, selectorCounts} = selectFilterAndSortSnps(snpListAnnotated, selectorsFiltersAndSort);

  const allSnpInterpretationGroups = [];
  snpListAnnotated.forEach(snpInterpretation => {
      const groupName = snpInterpretation["category_rml"] || snpInterpretation["group"];
      allSnpInterpretationGroups.includes(groupName) || allSnpInterpretationGroups.push(groupName);
  })

    return (
        <div className="App">
            <header className="App-header">
                <span className={"App-Title"}>SNParrot: Your Genome Talks to You</span>
            </header>
            <p>{INTRODUCTION}</p>
            <h1 className={"Section"}>Locate Your Genome Data</h1>
            <p className={"left-aligned-paragraph"}><i>Your data file is processed locally in this web browser and not transmitted anywhere.</i></p>
            <SelectAndLoadGenomeDataFile setGenomeDict={setMyGenome}/>
            <p/>
            <h1 className={"Section"}>Look Up Your RsIds</h1>
            <div>
                <RsIdsSearchForm disabled={!myGenome.isLoaded}
                                 selectors={rsIdSelectors}
                                 setSelectors={setRsIdSelectors}
                                 rsIdSelectionsList={rsIdSelectionsList}
                                 genomeIsLoaded={myGenome.isLoaded}/>
                <br/>
                {!myGenome.isLoaded
                    ? <span className={"search-error"}>No genome data to look up rsIds - locate your genome data file</span>
                    : <RsIdsTable rsIdSelectionsList={rsIdSelectionsList}/>}
            </div>
            <h1 className={"Section"}>Genome Interpretation</h1>
            {(typeof snpInterpretationsDict !== 'object' || Object.keys(snpInterpretationsDict).length === 0) ?
                <div>
                    <PassphraseForm setSnpInterpretationsDict={setSnpInterpretationsDict}/>
                </div> :
                <div>
                    <div className={"Event-Title"}>{eventInfo["name"]}</div>
                    {eventInfo["theme"] ? <div className={"Event-Subtitle"}>{eventInfo["theme"]}</div> : <span/>}
                    Stats...&nbsp;
                    <div style={{display: "inline-block", verticalAlign: "top"}}>{Object.keys(stats)?.map((key) =>
                        <div>{key} : {stats[key]} </div>)}
                    </div>
                    <p/>
                    <div className={"search-box"}>
                        <SnpsSearchForm selectors={selectorsFiltersAndSort.selectors}
                                        setSelectors={setSelectors}
                                        allSnpInterpretationGroups={allSnpInterpretationGroups}/>
                        <div>
                            {selectorCounts?.map(({selector, filteredSNPsCount, filteredOutSNPsCount}) =>
                                <span><button className={"lookup-lozenge"}
                                              onMouseUp={() => clearSelector(selector)}>{filteredSNPsCount} {selector.displayAs()} {filteredOutSNPsCount ? "(" + filteredOutSNPsCount + " no genome) " : ""}<span className={"deleter"}>X</span></button></span>)}
                        </div>
                    </div>
                    <div>
                        <p className={"button-panel"}>
                            <button name={"sort_main"} onMouseUp={toggleMainSort}>{selectorsFiltersAndSort.sort["alphaBySnpNameSort"] ? "Original order" : "Sort by SNP Name"}</button>
                            <button name={"filter_main"} disabled={!myGenome.isLoaded} onMouseUp={toggleJustMyGenomeFilter}>
                                {selectorsFiltersAndSort.filters["justMyGenomeViewFilter"] ? "Show all" : "Show my genome"}
                            </button>
                        </p>
                        <AnnotatedSNPsTable selectedFilteredAndSortedSNPs={selectedFilteredAndSortedSNPs}/>
                    </div>
                </div>
            }
            <p/>
            <hr/>
            <h2>The Fine Print</h2>
            <CopyrightAndDiscliamer practitionerDB={practitionerDB}/>
            <p/>
            <span>SNParrot.com Copyright ©2022 David P. Benjamin, Los Angeles, California.  Preview Edition. Latest Features:</span>
            <table>
                <tbody>
                <tr key={"feature3"}>
                    <td>2023-01-11</td>
                    <td>Lookups appear as lozenges with counts; highlight match in SNP interpretation</td>
                </tr>
                <tr key={"feature2"}>
                    <td>2022-12-15</td>
                    <td>Lookup rsId's takes a list; inspects some data files from 23andMe, Ancestry, Strategene, SelfDecode</td>
                </tr>
                <tr key={"feature1"}>
                    <td>2022-11-13</td>
                    <td>Quick lookups; SNPs sort alphabetically; hide not-genotyped rows</td>
                </tr>
                </tbody>
            </table>
            <footer className={"footer"}>
                <div>For any issue or to provide feedback, <a href={"mailto:snparrot-support@dbenjamin.com?subject=SNParrot%20feedback%20v0.1"}>please click here to send email</a> or use this address snparrot-support@dbenjamin.com</div>
                <div>Developed by <a href={"https://www.linkedin.com/in/david-p-benjamin"}>David Benjamin</a></div>
                <div>UI/UX and Graphic design by <a href={"https://www.linkedin.com/in/dreia-f-melinkoff/"}>Dreia Melinkoff</a></div>
            </footer>
        </div>
  );
}

export default App;




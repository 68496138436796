import {startDecryption} from "../../CryptFunctions";
import {SALT_IV, SNPS_ENC} from "../../Constants";
import {eventInfo, practitionerDB} from "../PractitionerDB";

const responseToTextBase64 =  (fetchResponse) => {
    if (!fetchResponse) throw new Error("fetchResponse is empty")
    let responseBase64 = fetchResponse.text()
    return responseBase64 // a Promise
}

const responseBinaryFromBase64 = (textResponseBase64) => {
    if (!textResponseBase64) {
        console.log("textResponseBase64 empty")
    }
    return Promise.resolve(atob(textResponseBase64))
}

const responseDecryptedFromBbinary = (textResponseBinary, passphrase, salt_iv) => {
    return startDecryption(textResponseBinary, passphrase.trim(), salt_iv)
}

const responseToJSON = (textResponse) => {
    if (!textResponse) {
        return Promise.reject("empty textResponse (failed decryption?)")
    }
    //console.log(textResponse)
    let jsonResponse = JSON.parse(textResponse)
    if (!jsonResponse) {
        return Promise.reject("empty jsonResponse (incorrect decryption?)")
    }
    //console.log("Done decoding...")
    //console.log(jsonResponse)
    return Promise.resolve(jsonResponse)
}

const composeSnpInterpretation = (fetchedSnpInterpretation) => {
    if (!fetchedSnpInterpretation) {
        console.log("empty fetchedSnpInterpretation")
        return Promise.reject("empty information download")
    }
    let snpInterpretationListAdditions = fetchedSnpInterpretation["snp_science"]  //TODO DPB rename this next time re-encoding
    return Promise.resolve(snpInterpretationListAdditions)
}

const fetchInterpretation = (snp_interpretation_source, practitionerVault, setPassphraseErrorMessage) => {
    const filename = snp_interpretation_source.file;
    const fetchResponsePromise = (SNPS_ENC[filename]) ?
        new Promise(function(myResolve) {
                myResolve(SNPS_ENC[filename]);
            }
        )
        : function() {
            const interpretation_path = snp_interpretation_source["path"] + "/" + filename;

            const headers = {'Content-Type': 'text/plain'}
            return fetch(process.env.PUBLIC_URL + "/" + interpretation_path, {headers})
                .then(
                    (response) => responseToTextBase64(response),
                    // TODO DPB -- server is returning index.html when the filename is missing on the server -- need to error for /db/
                    error => setPassphraseErrorMessage("Failed to fetch interpretation from server due to " + error))
        }()

    const interpretationDecryptedFromBbinary = (textResponseBinary) => {
        let iPassphrase = practitionerVault["vault"]["passphrases"][snp_interpretation_source.passphrase_index];
        //console.log(iPassphrase)
        let iSalt = new Uint8Array(practitionerVault["vault"]["salts"][snp_interpretation_source.IV_encryption_salt_index]);
        //console.log(iSalt)
        return startDecryption(textResponseBinary, iPassphrase, iSalt)
    }

    return fetchResponsePromise
        .then((base64) => responseBinaryFromBase64(base64)
                .then((cryptotext) => interpretationDecryptedFromBbinary(cryptotext)
                        .then((response) => responseToJSON(response)
                                .then((interpretationJson) => composeSnpInterpretation(interpretationJson)
                                        .then((interpretations) => { console.log("saved SNP interpretation"); return interpretations},
                                            error => setPassphraseErrorMessage("Failed to save interpretation JSON  because " + error)),
                                    error => setPassphraseErrorMessage("Failed to convert interpretation to JSON because " + error)),
                            error => setPassphraseErrorMessage("Failed to decrypt interpretation because " + error)),
                    error => setPassphraseErrorMessage("Failed interpretation atob() because " + error)),
            error => setPassphraseErrorMessage("Failed to open interpretation response because " + error))
        ;
}

const snpInterpretationAsDict = (snpInterpretationAsList) => {
    let newSnpSInterpretationDict = {}
    snpInterpretationAsList.map((snp) => newSnpSInterpretationDict[snp["snp_name"]] = snp)
    return newSnpSInterpretationDict
}

const extractSourceInfos = (practitionerDB, snpGroupsForEvent) => {
    let sourceNames = [];
    snpGroupsForEvent.forEach((snpGroup) => snpGroup.sources.forEach((source) => sourceNames.includes(source) ? null : sourceNames.push(source)));
    //console.log("Sources: " + sourceNames.join(","));
    let sourceInfos = sourceNames.map((name) => practitionerDB.snp_interpretation_sources[name]);
    //console.log("Source files: " + sourceInfos.map((info) => info.file).join(","))
    return sourceInfos;
}

const handlePractitionerVault = (practitionerVault, setSnpInterpretationsDict, setPassphraseErrorMessage) => {
    if (!practitionerVault) {
        console.log("Empty practitionerVault")
    }
    let promises = extractSourceInfos(practitionerDB, eventInfo["snp_groups"]).map((info) => fetchInterpretation(info, practitionerVault, setPassphraseErrorMessage)/* returns Promise */)
    return Promise.all(promises).then((snpInterpretationsListOfLists) =>{
        let snpInterpretationListWhole = []
        snpInterpretationsListOfLists.forEach((list) => list.forEach((item)=> snpInterpretationListWhole.push(item)))
        let newSnpInterpretationDict = snpInterpretationAsDict(snpInterpretationListWhole);
        setSnpInterpretationsDict(newSnpInterpretationDict)
    });
}

function processPassphrase(passphrase, setSnpInterpretationsDict, setPassphraseErrorMessage) {
    //console.log("Using: '" + passphrase + "'")
    // use passphrase to decrypt gene intepretation -- moves to "next page" / main page of the app where genomics can be loaded
    // TODO DPB
    // TODO DPB check for CYA string in decrypted JSON

    // AJAX FETCH (encrypted) INTERPRETATION FOR THIS PASSPHRASE
    // TODO DPB this is async...
    // TODO DPB put up waiting spinner
    const vault_url = process.env.PUBLIC_URL + practitionerDB.db_path + "/" + practitionerDB.practitioner_key + "_vault_json.enc";
    const headers = {'Content-Type': 'text/plain'}
    return fetch(vault_url, {headers})
        .then((fetchResponse) => responseToTextBase64(fetchResponse)
                .then((base64) => responseBinaryFromBase64(base64)
                        .then((cryptoText) => responseDecryptedFromBbinary(cryptoText, passphrase, practitionerDB.salt_iv)
                                .then((plainResponse) => responseToJSON(plainResponse)
                                        .then((vaultJson) => handlePractitionerVault(vaultJson, setSnpInterpretationsDict, setPassphraseErrorMessage)
                                                .then(() => console.log("Done caching vault and interpretations"),
                                                    error => setPassphraseErrorMessage("Please try a different passphrase. Failed using vault because " + error)),
                                            error => setPassphraseErrorMessage("Please try a different passphrase. Failed to convert vault to JSON because " + error)),
                                    error => setPassphraseErrorMessage("Please try a different passphrase. Failed to decrypt vault because " + error)),
                            error => setPassphraseErrorMessage("Please try a different passphrase. Failed vault atob() because " + error)),
                    error => setPassphraseErrorMessage("Failed to open vault response because " + error)),
            error => setPassphraseErrorMessage("Failed to fetch vault from server due to " + error))
        ;
}

export default processPassphrase;
import {AllSelector} from "./Selector";

const ALL_SELECTOR = new AllSelector()

const selectFilterAndSortSnps = (snpListAnnotated, {selectors, filters, sort}) => {
    /*
    For SNPs table, we want selections from snpListAnnotated.
    Each has the selector(s) that selected it for highlighting why it was selected.

    For the list of selector lozenges, we want how many SNPs selected by that lozenge are displayed (filtered out)

    The results, therefore, are as follows:
    {
      selectedFilteredAndSortedSNPs: [{annotatedSNP, selectors: [...]}],
      selectorCounts: [{selector, filteredSNPsCount, filteredOutSNPsCount}]
    }
     */

    const applyFilters = (snpInterpretation) => !filters["justMyGenomeViewFilter"] || snpInterpretation.my_genotype;

    let snpsResultList = [];
    let selectorCounts = (selectors.length ? selectors : [ALL_SELECTOR]).map((selector) => {
        const selections = selector.select({}/*genome*/, snpListAnnotated);
        const filteredSelections = selections.filter(applyFilters);
        const filteredSNPsCount = filteredSelections?.length || 0;
        const filteredOutSNPsCount = selections.length - filteredSNPsCount;
        filteredSelections.forEach((snpInterpretation) => {
            let found = snpsResultList.find((result) => result.snpInterpretation === snpInterpretation);
            if (found) {
                found.bySelectors = [...found.bySelectors, selector];
            } else {
                snpsResultList.push({snpInterpretation, bySelectors: [selector]}); // TODO DPB coalesce selectedSnps
            }
        })
        return {selector, filteredSNPsCount, filteredOutSNPsCount}
    });
    if (sort["alphaBySnpNameSort"]) {
        // Array.sort() sorts "in place" so needed to shallow copy first, which filter() did
        snpsResultList?.sort((a, b) => a.snpInterpretation.snp_name.localeCompare(b.snpInterpretation.snp_name))
    }
    return {
        selectedFilteredAndSortedSNPs: snpsResultList,
        selectorCounts: selectors.length ? selectorCounts : []
    };
}

export default selectFilterAndSortSnps;
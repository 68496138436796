import React from "react";
import sun_opsz48 from './sun_opsz48.svg'
import partly_cloudy_opsz48 from './partly_cloudy_opsz48.svg'
import umbrella_24 from './umbrella_24.svg'
//import EditSNP from "./EditSNP";

// Match columns / cells the way they are presented

const snpTableHeaderKeys = ["Group", "Name", /*"SNP function",*/ "Variant Name", "rsId", "Risk Allele", "My Status", "Functions", "Health Risks"]

const ICON_PARTLY_CLOUDY = <img src={partly_cloudy_opsz48} className="genome-sun" alt="sun"/>; // <i className="fa-solid fa-cloud-sun"/>
const ICON_SUN = <img src={sun_opsz48} className="genome-sun" alt="sun"/>; // <i className="fa-solid fa-sun"/>
const ICON_UMBRELLA = <img src={umbrella_24} className="genome-sun" alt="sun"/>; // <i className="fa-solid fa-umbrella"/>
const ICON_HOURGLASS = <i className="fa-regular fa-hourglass"/>;

function AnnotatedSNPsTable({selectedFilteredAndSortedSNPs}) {

    // TODO DPB drill down UX... const [showDetailsSNP, setShowDetailsSNP] = useState(snpListAnnotated[0])

    const highlightSnpGroupWithMatch = (text, selectors) => {
        if (typeof text !== 'string' && !(text instanceof String)) {
            return text;
        }
        return selectors.reduce((textSoFar, selector) => { return selector.highlightSnpGroup(textSoFar, "<span class='gibble'>$1</span>") }, text);
    }

    const highlightSnpNameStartsWithMatch = (text, selectors) => {
        if (typeof text !== 'string' && !(text instanceof String)) {
            return text;
        }
        return selectors.reduce((textSoFar, selector) => { return selector.highlightSnpName(textSoFar, "<span class='snibble'>$1</span>") }, text);
    }

    const highlightTextMatches = (text, selectors) => {
        if (!Array.isArray(text) && typeof text !== 'string' && !(text instanceof String)) {
            return text;
        }
        return selectors.reduce((textSoFar, selector) => { return selector.highlightText(textSoFar, "<span class='tibble'>$1</span>") }, text);
    }

    // TODO DPB !! IMPORTANT !! if ever displaying user-entered SNP Interpretations, then scrub out HTML tags, etc., to avoid CSS security risk

    return !Array.isArray(selectedFilteredAndSortedSNPs) || selectedFilteredAndSortedSNPs.length === 0 ?
        "No data to display" :
        <span>

             {/*     <EditSNP snpInterpretation={showDetailsSNP}/>*/}

            <p className={"left-aligned-paragraph"}>Printing hints: choose landscape and scale to 60%</p>
                <p/>
          <p>
                My Status Legend: {ICON_SUN} not this risk ; {ICON_PARTLY_CLOUDY} heterozygous; {ICON_UMBRELLA} homozygous;
                            ?? not genotyped; --no interpretation; {ICON_HOURGLASS} waiting for your data
          </p>
          <table id="snp-search-table-id" className={"snp-table"} width="100%">
            <thead key={"SNPs-head"}>
            <tr key={"SNPs-header"}>
                {snpTableHeaderKeys.map((key) => (
                    <th>{key}</th>
                ))}
            </tr>
            </thead>

            <tbody key={"SNPs-body"}>
            {
                selectedFilteredAndSortedSNPs.map((snpInterpretation, nthSNP) => {
                    let bySelectors = snpInterpretation["bySelectors"];
                    if (bySelectors) {
                        // handle case of selections/filter/sort
                        snpInterpretation = snpInterpretation.snpInterpretation;
                    } else {
                        bySelectors = []
                    }
                    const snpName = highlightSnpNameStartsWithMatch(snpInterpretation["snp_name"], bySelectors)
                    const snpNameSupplement = snpInterpretation["snp_name_supplement"]
                    const snpGroup = highlightSnpGroupWithMatch(snpInterpretation["category_rml"] || snpInterpretation["group"], bySelectors)
                    const snpFunctions = highlightTextMatches(snpInterpretation["snp_functions"], bySelectors)  // TODO DPB should functions/risks/tests be SNP level and variant level?
                    const variant_name = snpInterpretation["variant_name"]
                    const rsId = snpInterpretation["rsId"]
                    const riskAllele = snpInterpretation["risk_allele"]
                    const myGenotype = snpInterpretation["my_genotype"]
                    const myStatus = snpInterpretation["my_status"]
                    const snpHealthRisks = highlightTextMatches(snpInterpretation["snp_health_risks"], bySelectors)
                    const variantHealthRisks = highlightTextMatches(snpInterpretation["health_risks"], bySelectors)
                    return <tr key={"SNPs-" + nthSNP} className={"table-row"}>
                        <td dangerouslySetInnerHTML={{__html: snpGroup}}></td>
                        <td className={"table-cell-snp-name"} dangerouslySetInnerHTML={{__html: snpName + " " + (snpNameSupplement ? " (" + snpNameSupplement + ")" : "")}}/>
                        {/*<td className={"table-cell-snp-name"}><button onClick={() => setShowDetailsSNP(snpInfo)}>{snpName}{snpNameSupplement ? " (" + snpNameSupplement + ")" : ""}</button></td> */}
                        <td className={"table-cell-variant-name"}>{variant_name ? variant_name : "--"}</td>
                        <td>{rsId ? rsId : "-"}</td>
                        <td className={"table-cell-risk-allele"}>{riskAllele ? riskAllele : "--"}</td>
                        <td className={"table-cell-genotype"}><div className={"my-genotype"} >{myGenotype}</div><div className={"my-status-icon"} >{myStatus === 0 ?
                            ICON_SUN :
                            myStatus === 1 ? ICON_PARTLY_CLOUDY :
                                myStatus === 2 ? ICON_UMBRELLA :
                                    myStatus === 3 ? "??" :
                                        myStatus === 4 ? ICON_HOURGLASS :
                                            typeof myStatus === 'undefined' ? "--" :
                                                "-"}</div></td>
                        <td align={"left"} valign={"top"}>{snpFunctions ? snpFunctions.map((snpFunction) =>
                            <div dangerouslySetInnerHTML={{__html: snpFunction}}/>) : ""}</td>
                        <td align={"left"} valign={"top"}>{snpHealthRisks ? snpHealthRisks.map((risk) =>
                            <div dangerouslySetInnerHTML={{__html: risk}}/>) : ""}<span
                            className={"variant-health-risks"}>{variantHealthRisks ? variantHealthRisks.map((risk) =>
                            <div dangerouslySetInnerHTML={{__html: risk}}/>) : ""}</span></td>
                    </tr>;
                })}
            </tbody>
          </table>
        </span>
}

export default AnnotatedSNPsTable;
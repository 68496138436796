import React, {useState} from "react";
import {RsIdsSelector, SnpGroupSelector, SnpInfoDetailsContains, SnpStartsWithSelector} from "../services/Selector";

/*
Search blobs

rs list results in interpretations and in genome lookups (Utility: select all genome lookups?; display interpretations as just genome lookups?)
Result should be numerically sorted

    always result in interpretations...

snp starts with

full text search of functions & health risks

Action is X[i] (like sorting on that column)
Test is T[i] (like sorting on that column) & homo (non-green)

----------------
Sort / aggregate

Functions/risks word cloud (but somehow actionable)

Action is X[i] (like sorting on that column)
Test is T[i] (like sorting on that column) & homo (non-green)

 */

const SnpsSearchForm = ({selectors, setSelectors, allSnpInterpretationGroups}) => {

    const [searchSnpGroup, setSearchSnpGroup] = useState("");
    const [searchSnpNameStartsWith, setSearchSnpNameStartsWith] = useState("");
    const [searchRsId, setSearchRsId] = useState("");                              // TODO DPB rsId now has its own search form -- expunge from SnpsSearchForm
    const [searchSnpDetailsContains, setSearchSnpDetailsContains] = useState("")
    const [searchErrorMessages, setSearchErrorMessages] = useState([]);

    // TODO DPB use subsumes... const alreadySelected = (selector) => selectors.find(exists => exists.sameAs(selector)); // TODO DPB PREVENT DUPLICATES

    const handleLookupSubmit = (e) => {
        e.preventDefault();
        let errorMessages = []
        // if both snp name  and rsId are filled in use both
        // for rsId if present in setSnpInterpretationsDict, then use the associated snp-name
        // else TODO DPB [ ] LOOK UP rsId in myGenomeDict and add that to the output SOMEHOW
        // TODO DPB if not found in snpInterpretations, then just include rsId and mygenome lookup in the ultimate output (how???)
        if (searchRsId && searchRsId !== '') {
            const newSelector = new RsIdsSelector(searchRsId);
            setSelectors((existing) => [...existing, newSelector])
            setSearchRsId("")
        }
        if (searchSnpGroup && searchSnpGroup !== '') {
            const newGroupSelection = new SnpGroupSelector(searchSnpGroup);
            setSelectors((existing) => existing?.find(groupSelection => groupSelection.subsumes(newGroupSelection)) ? existing : [...existing, newGroupSelection]);
            setSearchSnpGroup("")
        }
        if (searchSnpNameStartsWith && searchSnpNameStartsWith !== '') {
            const newStartsWithSelection = new SnpStartsWithSelector(searchSnpNameStartsWith);
            setSelectors((existing) => existing?.find(startsWithSelection => startsWithSelection.subsumes(newStartsWithSelection)) ? existing : [...existing, newStartsWithSelection]);
            setSearchSnpNameStartsWith("")
        }
        if (searchSnpDetailsContains && searchSnpDetailsContains !== '') {
            const newDetailsContains = new SnpInfoDetailsContains(searchSnpDetailsContains);
            setSelectors((existing) => existing?.find(detailsContainsSelection => detailsContainsSelection.subsumes(newDetailsContains)) ? existing : [...existing, newDetailsContains])
            setSearchSnpDetailsContains("")
        }
        setSearchErrorMessages(errorMessages);
    };

    return <span className={"search-form"}>
        <form onSubmit={handleLookupSubmit}>
        Quick lookup:&nbsp;&nbsp;
            {/*<label key={"1"}>One or more rsIds<input
                name={"rsIdInput"}
                type={"text"}
                id={"rsIdInput"}
                value={searchRsId}
                maxLength={8120}
                minLength={2}
                onChange={(e) => {
                    setSearchErrorMessages([]);
                    setSearchRsId(e.target.value.toLowerCase());
                }}
            /></label>&nbsp;&nbsp;*/}{ allSnpInterpretationGroups.length < 2 ? <span/> : <label key={"SnpGroupInput"}>Group:<select
            name={"SnpGroupInput"}
            id={"SnpGroupInput"}
            value={searchSnpGroup}
            /*formLabel="Choose a service"
            buttonText="Send form"*/
            //defaultValue={{ label: "Click to see groups", value: "" }}
            //defaultValue={"Click to see groups"}
            onChange={(e) => {
                setSearchErrorMessages([]);
                setSearchSnpGroup(e.target.value);
            }}
        >
        <option value="Click to see groups" />
            {Array.from(allSnpInterpretationGroups).map(group =>
                <option value={group}>{group}</option>)}
      </select></label>}&nbsp;&nbsp;<label key={"snpNameStartsWithInput"}>Gene starts with:<input
            name={"snpNameStartsWithInput"}
            type={"text"}
            id={"snpNameStartsWithInput"}
            value={searchSnpNameStartsWith}
            maxLength={63}
            minLength={2}
            onChange={(e) => {
                setSearchErrorMessages([]);
                setSearchSnpNameStartsWith(e.target.value.toUpperCase());
            }}
        /></label>&nbsp;&nbsp;<label key={"snpDetailsContains"}>Function/Risk contains:<input
            name={"snpDetailsContains"}
            type={"text"}
            id={"snpDetailsContains"}
            value={searchSnpDetailsContains}
            maxLength={63}
            minLength={2}
            onChange={(e) => {
                setSearchErrorMessages([]);
                setSearchSnpDetailsContains(e.target.value);
            }}
        /></label>
            <button
                disabled={(!searchRsId || searchRsId === '') &&
                    (!searchSnpGroup || searchSnpGroup === '')  &&
                    (!searchSnpNameStartsWith || searchSnpNameStartsWith === '') &&
                    (!searchSnpDetailsContains && searchSnpDetailsContains === '')}
                type={"submit"}>Lookup</button>
            {searchErrorMessages.map((searchErrorMessage) => <div
                className={"search-error"}>{searchErrorMessage}</div>)}
            <div className={"button-panel"}>
                    <button name={"clear_lookup"}
                            disabled={!selectors || selectors.length === 0}
                            onMouseUp={() => {
                                setSelectors(() => []);
                                setSearchRsId("");
                                setSearchSnpGroup("");
                                setSearchSnpNameStartsWith("");
                                setSearchSnpDetailsContains("");
                                setSearchErrorMessages([]);
                            }}>Clear lookups</button>
            </div>
      </form>

      </span>
}

export default SnpsSearchForm
import {practitionerDB} from "../services/PractitionerDB";
import {COPYRIGHT_DISCLAIMER_TEXT} from "../Constants";

const CopyrightAndDiscliamer = () =>
    <span style={{textAlign: "left"}}>
    {practitionerDB["copyright_disclaimer_html_generator"]
        ? practitionerDB["copyright_disclaimer_html_generator"](practitionerDB)
        : practitionerDB["copyright_disclaimer_text"]
            ? practitionerDB["copyright_disclaimer_text"]
            : COPYRIGHT_DISCLAIMER_TEXT}
    </span>

export default CopyrightAndDiscliamer

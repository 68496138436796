import React, {useState} from "react";
import ingestGenomeData from "../services/IngestGenome";
import {TRACE_INGEST} from "../Constants";

const ICON_HOURGLASS = <i className="fa-regular fa-hourglass"/>;

const SelectAndLoadGenomeDataFile = ({setGenomeDict}) => {

    const [genomeFile, setGenomeFile] = useState();
    const [genomeFileErrorMessage, setGenomeFileErrorMessage] = useState("");
    const [genomeIngestErrorMessages, setGenomeIngestErrorMessages] = useState([]);
    const [progressFeedback, setProgressFeedback] = useState(undefined);

    const fileReader = new FileReader();

    const ingestGenome = (genomeFile) => {
        if (!genomeFile) {
            if (TRACE_INGEST) {
                console.log("handleOnSubmit: file not defined")
            }
            setGenomeIngestErrorMessages(["Internal error: lost genome file name"])
            return;
        }
        fileReader.onload = function (event) {
            // TODO DPB -- if a .zip file, then include unzip in read sequence
            const text = event.target.result;
            if (TRACE_INGEST) {
                console.log("Loaded genetic data file. Data size is " + text.length)
            }
            let [genomeDict, ingestErrors, ingestWarnings] = ingestGenomeData(text)
            let isGenomeLoaded = false;
            let freshEntriesCount = undefined;
            if (ingestErrors && ingestErrors.length > 0) {
                genomeDict = {}
            } else {
                freshEntriesCount = !genomeDict || Object.keys(genomeDict).length || undefined;
                if (!freshEntriesCount) {
                    ingestErrors.push("Ingested the genome file and did not find any genes; please check the file you chose")
                } else {
                    isGenomeLoaded = true;
                }
            }
            setGenomeIngestErrorMessages(ingestErrors ? ingestErrors : []) // TODO NEED TO SET UP CLEARING; NEED TO STATE ACTION-TO-TAKE
            setProgressFeedback("Located.  Your data has " + freshEntriesCount + " entries");
            setGenomeDict(Object.assign({}, {isLoaded: isGenomeLoaded, dict: genomeDict}));
        };
        if (TRACE_INGEST) {
            console.log("Reading file as genetic data...")
            console.log(genomeFile)
        }
        setProgressFeedback("Accessing data file...");  // TODO DPB only show this if more than 1 second elapses
        setGenomeDict(Object.assign({}, {isLoaded: false, dict: undefined}));   // be sure ref changes to trigger React update
        fileReader.readAsText(genomeFile);
    }

    const handleGenomeFileChoice = (e) => {
        e.preventDefault();
        let file = e.target.files[0];
        if (file.name !== genomeFile?.["name"]) {  // TODO DPB what if directory is distinct for new file compared to previous?
            // TODO DPB -- if a .zip file, then include unzip in read sequewnce
            const validFilename = file.name.endsWith(".txt");
            if (validFilename) {
                setGenomeFile(file);
                setGenomeFileErrorMessage( "");
                setGenomeIngestErrorMessages([]);  // TODO DBP only set once per interaction to avoid non-determinism
                // setProgressFeedback(undefined); // TODO DBP only set once per interaction to avoid non-determinism
                ingestGenome(file);
            } else {
                setGenomeFileErrorMessage("Please choose a file ending in .txt.  Windows users: right-click and select 'extract' on 23andMe's .zip file. Mac users double click the .zip file");
                setGenomeIngestErrorMessages([]);  // TODO DBP only set once per interaction to avoid non-determinism
                setProgressFeedback(undefined); // TODO DBP only set once per interaction to avoid non-determinism
            }
        }
    };

    return <form>
        <table id={"find-genome-table"} className={"find-genome-table"}>
            <tbody>
            <tr key={"genome-file"}>
                <td className={"user-instruction, choose-file-td"}>
                    <label key={"genesFileInput"}>Click/tap below and locate your downloaded genome data file that ends in '.txt'<br/>
                        <input
                            type={"file"}
                            id={"genesFileInput"}
                            /*accept={".csv"}*/
                            onChange={handleGenomeFileChoice}
                        /></label>
                    <div className={"error-message"}>{genomeFileErrorMessage}</div>
                </td>
                <td><span
                    className={progressFeedback ? "success-message" : "error-message"}
                >{progressFeedback ? progressFeedback /* TODO DPB add warnings */ :
                    genomeFile ? genomeIngestErrorMessages.map(m => <div>{m}</div>) :
                        "Press the button to locate your genome data file"  // TODO DPB review this logic
                }</span>
                </td>
            </tr>
            </tbody>
        </table>
    </form>
}

export default SelectAndLoadGenomeDataFile;
